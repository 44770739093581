<template>
  <div class="container">
    
    <HelloWorld msg="Examen d'équivalence Français. Spécialités médicales" description="<ul><li>la procédure d’autorisation d’exercice s’adressant aux praticiens à diplômes hors UE lauréats des épreuves de vérification des connaissances (concours ou examen) ;</li><li>
la procédure d’autorisation d’exercice s’adressant à des ressortissants d’un État membre de l‘UE (ou d’un État partie à l’accord sur l’espace économique européen) titulaires de titres de formation délivrés par l’un de ces États ou par un État tiers et reconnus par un État, membre ou partie ;</li></ul>" urlimage="examen_francais.png"/>
  
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloComposant.vue'

export default {
  name: 'EEF',
  components: {
    HelloWorld
  }
}
</script>
