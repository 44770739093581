<template>
 <div class="jumbotron">
    <div class="container bg-secondary" >
        <div class="row intro-full-screen" >
              <div class="col-md-6 col-xs-12 align-self-center text-left bg-secondary">
                <div class="card bg-secondary">
                    <div class="card-body">
                      <h3>{{ msg }}</h3> 
                      <div v-html="description"></div>
                      
                        <a class="nav-link" href="/Contact">
                          <button type="button" class="btn btn-secondary"  style="margin-bottom: 0em;">Participer</button>
                        </a>
                        <a class="nav-link" href="#More">
                          <button type="button" class="btn btn-outline-primary"  style="margin-bottom: 1em;">En savoir plus</button>
                        </a>
                    </div>
                </div>
              </div>
              <div class="text-center col-md-6 col-xs-6 bg-secondary" style="padding-top: 5%;">
                <div class="card bg-secondary">
                    <div class="">
                      <img v-bind:src="require(`@/assets/${urlimage}`)" alt="illustration groupe">
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
        </div>
          <!--<hr height="1px"color="fff" width="20%"/>-->
    </div>
  </div>
 
</template>

<script>
export default {
  name: 'HelloWorld',
  
  props: {
    msg: String,
    description :String,
    urlimage : String
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-secondary {
    background-color: #ffffff !important;
}
.card {
      border: 0px solid rgba(0, 0, 0, 0.125);
}
</style>
